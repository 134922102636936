import { useCurrentLocale, useLocaleList } from '@/composables/locale'
import { useRoute } from '@/composables/route'
import { router } from '@inertiajs/vue3'
import { defineComponent } from 'vue'

export default defineComponent({
  setup(props, { slots }) {
    return () =>
      slots.default({
        current: useCurrentLocale(),
        list: useLocaleList(),
        change: (locale) =>
          router.post(
            useRoute('locales.switch', { locale }),
            {},
            { onSuccess: () => location.reload() },
          ),
      })
  },
})
