<template>
  <Alert
    :show="show"
    :title="$t('common.mobile.menu.disabled.title')"
    :message="$t('common.mobile.menu.disabled.text')"
    :display-cancel-button="false"
    :confirm-text="$t('common.close')"
    @confirm="emit('confirm')"
  />
</template>

<script setup>
import Alert from '@/Components/Alert.vue'
defineProps({
  show: {
    type: Boolean,
    default: () => false,
  },
})

const emit = defineEmits(['confirm'])
</script>
