<template>
  <div
    v-if="isNotificationShown"
    class="relative min-w-[250px] overflow-hidden rounded bg-[#F6F7F8] py-3 px-5 text-sm shadow-[0_6px_19px_0_rgba(182,182,182,0.35)]"
    data-test="alert-container"
  >
    <div class="absolute top-0 left-0 h-full w-[5px] bg-primary"></div>
    <a
      class="absolute top-1 right-2 cursor-pointer"
      @click="isNotificationShown = false"
    >
      <FontAwesomeIcon
        :icon="['fal', 'times']"
        class="text-base text-[#3d4146]"
      />
    </a>

    <h3
      class="font-normal text-primary"
      v-text="headline || $t('common.success')"
    />
    <p v-if="message" class="font-semibold text-[#3d4146]" v-text="message" />
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  headline: {
    type: [String, null],
    default: null,
  },
  message: {
    type: [String, Object, null],
    default: null,
  },
})

const isNotificationShown = ref(true)
</script>
