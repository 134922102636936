import { usePermission } from '@/composables/permission.js'
import { useFeature } from '@/composables/feature.js'

export const useMenus = [
  {
    name: 'Events/Cockpit',
    label: 'cockpit',
    icon: ['fas', 'chart-pie'],
    route: 'events.cockpit',
    params: [],
    permission: 'event:statistic:view',
    activeRoutes: [],
    child: [],
    buttons: [],
    selector: 'header_menu_home',
  },
  {
    name: 'Bookings/List',
    label: 'guestlist',
    icon: ['fas', 'user-check'],
    route: 'bookings.index',
    params: ['event.id'],
    permission: 'guest:list',
    activeRoutes: [],
    child: [],
    buttons: [],
    selector: 'header_menu_guestlist',
  },
  {
    name: 'LandingPages/CenterstageEmbed',
    label: 'landingpage',
    icon: ['far', 'browser'],
    route: 'landingPages.centerstage.embed',
    params: ['event.id'],
    activeRoutes: [],
    child: [],
    buttons: [],
    permission: 'centerstage:view',
    selector: 'header_menu_landingpage',
    desktopOnly: true,
  },
  {
    name: 'Emails/Templates/List',
    label: 'messages',
    icon: ['fas', 'envelope-circle-check'],
    route: 'emails.templates.index',
    params: ['event.id'],
    dropdown: true,
    activeRoutes: [],
    permission: 'email:template:list',
    desktopOnly: true,
    isMobileChildMenuShown: false,
    childSubmenu: true,
    child: [
      {
        name: 'Emails/Templates/List',
        label: 'email',
        icon: ['fal', 'browser'],
        route: 'emails.templates.index',
        permission: 'email:template:list',
        submenu: [
          {
            name: 'Emails/Templates/List',
            label: 'email_templates',
            icon: ['fal', 'browser'],
            route: 'emails.templates.index',
            permission: 'email:template:list',
          },
          {
            name: 'Emails/SenderIdentity/List',
            label: 'email_senders',
            icon: ['fal', 'envelope-open-text'],
            route: 'events.settings.senderIdentities.index',
            permission: 'email:senderIdentity:list',
          },
          {
            name: 'Emails/Statistic/Index',
            label: 'email_statistics',
            icon: ['fal', 'chart-pie-alt'],
            route: 'emails.statistics',
            permission: 'email:statistic:view',
          },
          {
            name: 'Emails/Sent/List',
            label: 'sent_emails',
            icon: ['fal', 'envelope-open-text'],
            route: 'emails.sent.list',
            permission: 'email:sent:list',
          },
          {
            name: 'Emails/EmailSubscription/List',
            label: 'opt_in_history',
            route: 'emails.subscription.list',
            permission: 'contact:emailSubscription:list',
          },
          // {
          //   name: 'Emails/Settings/CustomMailers/Index',
          //   label: 'email_settings',
          //   icon: ['fal', 'cogs'],
          //   route: 'emails.settings.customMailers.index',
          //   permission: 'email:customMailer:list',
          // },
        ],
      },
      {
        name: 'Sms/Sent/List',
        label: 'sms',
        route: 'sms.sent.list',
        permission: 'sms:sent:list',
        check_sms_enabled: true,
        submenu: [
          {
            name: 'Sms/Templates/List',
            label: 'sms_template',
            route: 'sms.templates.list',
            permission: 'sms:sent:list',
          },
          {
            name: 'Sms/Sent/List',
            label: 'sent_sms',
            route: 'sms.sent.list',
            permission: 'sms:sent:list',
          },
          {
            name: 'Sms/Statistics/Index',
            label: 'sms_statistics',
            route: 'sms.statistics',
            permission: 'sms:statistic:view',
          },
          {
            name: 'Sms/SenderIdentities/List',
            label: 'sms_sender_identities',
            route: 'events.settings.smsSenderIdentities.index',
            permission: 'sms:senderIdentity:list',
          },
          {
            name: 'Sms/Pricing/List',
            label: 'sms_pricing',
            route: 'sms.pricing.list',
            permission: 'sms:sent:list',
          },
        ],
      },
    ],
    buttons: [],
    selector: 'header_menu_emails',
  },
  {
    name: 'Documents/List',
    label: 'documents',
    icon: ['fas', 'grid-2-plus'],
    route: 'documents.index',
    params: ['event.id'],
    dropdown: false,
    activeRoutes: [],
    permission: 'document:list',
    feature: 'documents',
    desktopOnly: true,
    isMobileChildMenuShown: false,
    child: [],
    buttons: [],
    selector: 'header_menu_documents',
  },
  {
    name: 'Tickets/List',
    label: 'tickets',
    icon: ['fas', 'qrcode'],
    route: 'tickets.index',
    params: ['event.id'],
    dropdown: true,
    activeRoutes: [],
    permission: 'ticket:view',
    desktopOnly: true,
    isMobileChildMenuShown: false,
    child: [
      {
        name: 'Tickets/Pdfs/Update',
        label: 'pdf_ticket',
        icon: ['fal', 'file-pdf'],
        route: 'tickets.pdfs.show',
        permission: 'ticket:pdf:view',
      },
      {
        name: 'Tickets/Wallets/Update',
        label: 'wallet_ticket',
        icon: ['fal', 'mobile-alt'],
        route: 'tickets.wallets.show',
        permission: 'ticket:wallet:view',
      },
    ],
    buttons: [],
    selector: 'header_menu_tickets',
  },
  {
    name: 'TableSeating',
    label: 'seating',
    icon: ['fas', 'loveseat'],
    route: 'tableSeating.embed',
    params: ['event.id'],
    dropdown: true,
    activeRoutes: [],
    child: [
      {
        name: 'TableSeating/TableSeatingEmbed',
        label: 'tableseating_manage',
        icon: ['fal', 'file-pdf'],
        route: 'tableSeating.embed',
        permission: 'tableSeating:view',
      },
      {
        name: 'Bookings/Seats/List',
        label: 'tableseating_table',
        icon: ['fal', 'file-pdf'],
        route: 'bookings.seats.list',
        permission: 'guest:seat:list',
      },
    ],
    buttons: [],
    permission: 'tableSeating:view',
    selector: 'header_menu_seating',
    desktopOnly: true,
    isMobileChildMenuShown: false,
  },
  {
    name: 'Events/Settings/Settings',
    label: 'settings',
    icon: ['fas', 'gear'],
    route: 'events.settings.show',
    params: ['event.id'],
    dropdown: true,
    isMobileChildMenuShown: false,
    activeRoutes: [],
    permission: 'event:setting:view',
    child: [
      {
        name: 'Events/Settings/Settings',
        label: 'event_information',
        icon: ['fal', 'chart-pie-alt'],
        route: 'events.settings.show',
        permission: 'event:setting:view',
      },
      {
        name: 'Events/Settings/Advanced',
        label: 'event_settings',
        icon: ['fal', 'cogs'],
        route: 'events.settings.advanced.show',
        permission: 'event:setting:advanced:view',
      },
      {
        name: 'Bookables/Groups',
        label: 'Bookable groups',
        icon: ['fal', 'file-pdf'],
        route: 'bookable.groups.list',
        feature: 'bookable-objects',
        permission: 'bookableObject:group:list',
      },
      {
        name: 'Events/Limits/List',
        label: 'limits',
        icon: ['fal', 'exclamation-triangle'],
        route: 'events.settings.fieldLimits.index',
        permission: 'extendedField:limit:list',
      },
    ],
    buttons: [],
    selector: 'header_menu_settings',
  },
]

export const getMenu = (menu) => {
  return useMenus.find(({ name }) => menu.toLowerCase().includes(name))
}

export const getPage = (route) => {
  return useMenus.find(({ name }) => route.toLowerCase().includes(name))?.label
}

export const useHelpCenterUrl = () => {
  return 'https://help.airlst.app/'
}

export const useActiveMenuElements = () => {
  return useMenus.filter((menu) => {
    let hasPermission = usePermission(menu.permission)
    let featureEnabled = menu.feature?.length ? useFeature(menu?.feature) : true

    return hasPermission && featureEnabled
  })
}
