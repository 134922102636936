<template>
  <div
    :class="[
      'animate-dropdown relative z-[9999] flex h-full cursor-pointer items-center gap-[6px] tracking-[0.2px] text-white subpixel-antialiased',
      $page.component === 'Dashboard'
        ? 'text-white'
        : 'text-[rgba(255,255,255,0.5)] hover:text-white',
    ]"
  >
    <Link
      :class="[
        'group relative flex cursor-pointer items-center gap-[4px] text-sm',
        $page.component === 'Dashboard'
          ? 'text-white'
          : 'text-[rgba(255,255,255,0.5)] hover:text-white',
      ]"
      :href="useRoute('dashboard')"
    >
      <FontAwesomeIcon
        :icon="['fas', 'party-horn']"
        class="mr-[6px] mb-[3px] text-lg text-[#9C63FF]"
      />
      <span
        v-if="!pageHasEvent"
        :class="[
          'mr-[2px] inline-block border-b-2 py-[8px]',
          $page.component === 'Dashboard'
            ? 'border-[#9c63ff]'
            : 'whitespace-nowrap border-transparent group-hover:border-[#9c63ff]',
        ]"
        v-text="$t('common.my_events')"
      />
      <FontAwesomeIcon :icon="['fas', 'angle-down']" class="mb-[3px] text-sm" />
    </Link>
    <div
      :class="[
        'dropdown-div absolute top-[55px] max-h-[400px] w-[300px] rounded-[0_0_5px_5px] tracking-[0.2px] text-[#7b8190] subpixel-antialiased',
      ]"
    >
      <div class="h-full pt-[23px]">
        <div
          class="bg-white p-[10px_15px_10px_15px] shadow-[0_5px_6px_rgb(52,58,64,0.10)]"
        >
          <h4
            class="mb-[8px] text-[11px] font-bold uppercase tracking-[1px] text-[#3f1e59] opacity-[0.4]"
            v-text="$t('common.my_events')"
          />

          <div class="relative mb-[16px]">
            <FontAwesomeIcon
              :icon="['fas', 'search']"
              class="absolute top-[10px] left-[8px] text-base text-[#7b8190]"
            />
            <input
              v-model="search"
              type="text"
              class="h-[36px] w-full rounded-[3px] border border-[#d9e1e6] bg-white p-[5px_10px_5px_30px] text-sm font-normal text-[#999] placeholder-[#212529] placeholder-opacity-50 focus:border-[#a944b3cc] focus:shadow-violet-input focus:outline-none"
              :placeholder="$t('event.search_event')"
              @keyup="searchEvents"
            />
          </div>

          <div
            v-if="loading"
            class="flex gap-x-2 justify-center items-center py-4 text-center text-xs italic"
          >
            <FontAwesomeIcon
              :icon="['fal', 'spinner']"
              class="text-lg text-primary"
              spin
            />
            {{ $t('common.loading') }}
          </div>

          <div
            v-if="!loading"
            class="mx-[-15px] max-h-[200px] overflow-y-auto px-[15px]"
          >
            <template
              v-for="{ id, name, timezone, starts_at } in events"
              :key="id"
            >
              <div
                class="py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
                @click="
                  router.get(
                    useRoute('events.cockpit', {
                      event: id,
                    }),
                  )
                "
              >
                <p class="text-xs font-bold" v-text="name" />
                <span
                  class="block text-[10px] font-medium text-[#3f1e59]"
                  v-text="formatDate(starts_at, timezone)"
                />
              </div>

              <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
            </template>

            <div
              v-if="!events.length"
              class="border-b border-dashed border-[#dbdbdb] py-4 text-center text-xs italic"
              v-text="$t('common.no_results_found')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatDate } from '@/composables/date'
import { useRoute } from '@/composables/route'
import { useEcho } from '@/composables/useEcho'
import { getJson } from '@/composables/useFetch'
import { router, Link, usePage } from '@inertiajs/vue3'
import { useDebounceFn } from '@vueuse/core'
import { ref, computed } from 'vue'

const pageHasEvent = computed(() => usePage().props.event)
const events = ref([])
const search = ref('')
const loading = ref(true)

const getEvents = async () => {
  loading.value = true
  const { data } = await getJson(
    useRoute('events.search', { search: search.value }),
  )
  events.value = data.value.events
  loading.value = false
}

await getEvents()

const searchEvents = useDebounceFn(() => getEvents(), 500)

useEcho()
  .private(`companies.${usePage().props._user.active_company.id}`)
  .listen('.event:created', () => getEvents())
  .listen('.event:deleted', () => getEvents())
  .listen('.event:updated', () => getEvents())

useEcho()
  .private(`users.${usePage().props._user.id}`)
  .listen('.company:switched', () => getEvents())
</script>
