<template>
  <main
    class="cloud-move bg-white bg-cover md:bg-contain bg-no-repeat md:bg-repeat-x flex flex-col h-screen"
    :style="{
      'background-image': `url('${Vapor.asset('images/bg-cloud-main.png')}')`,
      'background-repeat-y': 'no-repeat',
    }"
  >
    <FreePlanBanner v-if="activeCompany && onFreePlan" />
    <Header />

    <div class="flex-1 flex flex-col overflow-y-auto">
      <article class="relative flex-1">
        <slot />
      </article>
      <Footer />
    </div>

    <Chat v-if="enableChat" />

    <div
      class="fixed top-20 right-5 z-[99] space-y-2"
      data-test="notifications-container"
    >
      <Notifications @new-notification="onNewNotification">
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <NewNotification
            v-if="showNewNotification"
            :notification="newNotificationContent"
          />
        </transition>
      </Notifications>

      <NotificationsList :notification="useFlash('bannerNotification')" />
    </div>

    <SubscriptionAlert />
  </main>
</template>

<script setup>
import Footer from '@/Components/Footer.vue'
import Header from '@/Components/Header.vue'
import FreePlanBanner from '@/Components/FreePlanBanner.vue'
import Chat from '@/Components/Headless/Chat'
import SubscriptionAlert from '@/Components/SubscriptionAlert.vue'
import Vapor from 'laravel-vapor'
import { useFlash } from '@/composables/useFlash'
import { hasChatAccess } from '@/composables/subscription'
import { usePage } from '@inertiajs/vue3'
import NewNotification from '@/Components/Notifications/NewNotification.vue'
import NotificationsList from '@/Components/Notifications/NotificationsList.vue'
import { ref, computed } from 'vue'
import Notifications from '@/Components/Headless/Notifications'

const activeCompany = usePage().props._user.active_company

// chat enabled for free and premium companies
const enableChat = hasChatAccess() || !activeCompany?.subscribed

const onFreePlan =
  !activeCompany?.enterprise &&
  !activeCompany?.subscribed &&
  activeCompany.event_subscription === null

const newNotificationContent = ref({})
const showNewNotification = computed(
  () => Object.keys(newNotificationContent.value).length > 0,
)
const onNewNotification = (notification) => {
  newNotificationContent.value = notification
  setTimeout(() => (newNotificationContent.value = {}), 5000)
}
</script>
