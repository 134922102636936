import { usePage } from '@inertiajs/vue3'
import { enGB, de } from 'date-fns/locale'

export const useCurrentLocale = () => usePage().props._locales.current
export const useCurrentLocaleCode = () => useCurrentLocale().code
export const useSimplifiedCurrentLocale = () =>
  useCurrentLocaleCode().split('-')[0]
export const useLocaleList = () => usePage().props._locales.list
export const useDateFnsLocales = () =>
  useCurrentLocaleCode() === 'de-DE' ? de : enGB
