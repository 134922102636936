import { usePusherCredentials } from '@/composables/credentials'
import Echo from 'laravel-echo'

let echo = null

export const useEcho = () => {
  if (!echo) {
    echo = new Echo({
      broadcaster: 'pusher',
      enabledTransports: ['ws', 'wss'],
      disableStats: true,
      ...usePusherCredentials(),
    })
  }

  return echo
}
