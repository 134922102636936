import { usePage } from '@inertiajs/vue3'

export const useFlash = (property) => {
  const payload = JSON.parse(usePage().props._flash)?.payload ?? null

  if (!payload) {
    return null
  }

  if (payload === property || typeof property === 'undefined') {
    return payload
  }

  return payload[property] ?? null
}

export const setFlash = (payload) =>
  (usePage().props._flash = JSON.stringify({ payload }))
