<template>
  <div>
    <SuspenseWrapper without-loading>
      <MediaUpload
        :key="mediaUploadKey"
        :initial-value="initialValue ? initialValue : []"
        image
        @upload-in-progress="handleUploadProgress"
        @changed="(avatar) => (changedAvatar = avatar)"
      >
        <template
          #default="{
            getFileInputListeners,
            removeMedia,
            state,
            clearInvalidMedia,
            getImgProps,
          }"
        >
          <div :class="'relative block w-[' + size + '] mx-auto'">
            <FontAwesomeIcon
              v-if="uploading"
              :icon="['fal', 'spinner']"
              class="absolute text-base text-[#9158ff]"
              spin
            />

            <button
              v-if="state.media.length && allowDelete"
              type="button"
              class="absolute bottom-0 right-[-30px] h-[30px] w-[30px] rounded-full bg-[#de3939]"
              data-test="delete-btn"
              @click="
                () => {
                  removeMedia(state.media[0])
                  emit('deleted')
                }
              "
            >
              <FontAwesomeIcon
                :icon="['fal', 'trash-alt']"
                class="text-base text-white"
              />
            </button>

            <div
              :class="
                'relative h-[' +
                size +
                '] w-[' +
                size +
                '] rounded-full' +
                (state.media.length ? ' border' : '')
              "
            >
              <button
                v-if="allowUpdate"
                type="button"
                class="absolute bottom-0 right-2 h-[30px] w-[30px] rounded-full bg-[#9158ff]"
                data-test="update-btn"
              >
                <FontAwesomeIcon
                  :icon="['fas', 'pencil']"
                  class="text-base text-white"
                />
              </button>

              <img
                v-if="state.media.length"
                class="h-full w-full rounded-full"
                v-bind="getImgProps(state.media[0])"
              />

              <FontAwesomeIcon
                v-else
                :icon="['fas', 'user-circle']"
                :class="
                  'h-[' +
                  size +
                  '] w-[' +
                  size +
                  '] text-[' +
                  size +
                  '] text-[#9158ff] opacity-[.4]'
                "
              />

              <input
                v-if="allowUpdate"
                class="z-2 absolute top-0 left-0 h-full w-full opacity-0"
                type="file"
                @change="
                  (e) => {
                    clearInvalidMedia()
                    getFileInputListeners()['changed'](e)
                  }
                "
              />
            </div>
          </div>
        </template>
      </MediaUpload>
    </SuspenseWrapper>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import MediaUpload from '@/Components/MediaUpload.vue'
import SuspenseWrapper from '@/Components/common/SuspenseWrapper.vue'

const props = defineProps({
  initialValue: {
    type: [Array, Object],
    required: false,
    default: () => [],
  },
  allowUpdate: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  allowDelete: {
    type: Boolean,
    required: false,
    default: () => true,
  },
  size: {
    type: String,
    required: false,
    default: () => '136px',
  },
})

const emit = defineEmits(['upload-in-progress', 'changed', 'deleted'])

const uploading = ref(false)
const changedAvatar = ref(null)

const handleUploadProgress = (progressing) => {
  uploading.value = progressing
  emit('upload-in-progress', uploading.value)

  if (!uploading.value && changedAvatar.value) {
    emit('changed', changedAvatar.value)
  }
}

const mediaUploadKey = ref()

watch(
  () => props.initialValue,
  () => (mediaUploadKey.value += 1),
)
</script>
