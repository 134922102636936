<template>
  <TransitionRoot v-if="subscriptionError" appear show>
    <Dialog
      as="div"
      open
      class="fixed top-0 -left-[2px] z-50 h-screen w-full overflow-y-auto tracking-[0.2px] subpixel-antialiased"
      @close="close"
    >
      <TransitionChild
        as="template"
        enter="duration-200 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay
          class="fixed top-0 left-0 h-full w-full bg-black bg-opacity-50"
        />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="duration-200 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="h-screen overflow-y-auto">
          <div class="flex min-h-screen justify-center py-10 items-center">
            <div
              class="relative max-w-[95%] p-10 rounded-[10px] bg-white shadow-[0px_6px_19px_0px_rgba(182,182,182,0.35)]"
              style="width: 478px"
            >
              <button
                type="button"
                class="absolute top-2 right-4 cursor-pointer"
                @click="close"
              >
                <FontAwesomeIcon
                  :icon="['fas', 'times']"
                  class="text-black text-lg"
                />
              </button>

              <div class="p-[17px_17px_25px_17px">
                <img
                  :src="Vapor.asset('images/attention.png')"
                  alt=""
                  class="mx-auto my-[15px] h-[175px] w-[175px]"
                />
                <slot>
                  <div class="text-center">
                    <h3
                      class="mb-[10px] text-2xl font-semibold text-[#797979]"
                      v-text="
                        message ??
                        $t('event.subscription_limit.headline_boolean')
                      "
                    />
                    <p
                      class="text-sm font-light text-[#797979]"
                      v-text="description"
                    />
                  </div>
                </slot>
                <slot name="buttons">
                  <div class="mt-6 flex items-center justify-center gap-2">
                    <a
                      href="mailto:support@airlst.com"
                      class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-3 py-2 text-sm font-medium text-white hover:bg-[#6c33f7] sm:w-auto"
                      @click="emit('close')"
                      v-text="$t('settings.contact_us')"
                    />
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { useFlash } from '@/composables/useFlash'
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { router } from '@inertiajs/vue3'
import Vapor from 'laravel-vapor'
import { computed } from 'vue'

const props = defineProps({
  error: {
    type: Object,
    required: false,
    default: null,
  },
})

const emit = defineEmits(['close'])

const subscriptionError = computed(
  () => useFlash('subscriptionError') ?? props.error ?? null,
)
const message = computed(() => subscriptionError.value?.message)
const description = computed(() => subscriptionError.value?.description)

const close = () => {
  emit('close')
  router.reload()
}
</script>
