import { setFlash } from '@/composables/useFlash'
import { createFetch } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/composables/route'

export const useFetch = createFetch({
  options: {
    updateDataOnError: true,
    async beforeFetch({ options }) {
      options.headers['Content-Type'] = 'application/json'
      options.headers.Accept = 'application/json'
      options.headers['X-XSRF-TOKEN'] = useCookies().get('XSRF-TOKEN')

      return { options }
    },
    onFetchError(ctx) {
      if (ctx.response.status === 402) {
        setFlash({ subscriptionError: ctx.data })
      }

      if (ctx.response.status === 423) {
        return router.visit(useRoute('password.confirm'))
      }

      return ctx
    },
  },
})

export const getJson = (url, options) =>
  useFetch(url, options ?? {})
    .get()
    .json()

export const postJson = (url, payload, options) =>
  useFetch(url, options ?? {})
    .post(payload ?? {})
    .json()

export const putJson = (url, payload, options) =>
  useFetch(url, options ?? {})
    .put(payload ?? {})
    .json()

export const deleteJson = (url, payload, options) =>
  useFetch(url, options ?? {})
    .delete(payload ?? {})
    .json()
