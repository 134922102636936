<template>
  <div
    class="pointer-events-auto relative z-50 min-w-[300px] overflow-hidden rounded bg-[#F6F7F8] py-3 px-5 text-sm shadow-[0_6px_19px_0_rgba(182,182,182,0.35)]"
  >
    <div class="absolute top-0 left-0 h-full w-[5px] bg-primary"></div>

    <p class="font-normal text-primary" v-text="notification.title" />
    <p
      v-if="notification.description"
      class="font-semibold text-[#3d4146]"
      v-text="notification.description"
    />
    <div class="flex justify-end gap-5 pt-3">
      <button
        type="button"
        class="text-xs font-medium text-gray-700 outline-none hover:text-gray-500 hover:underline"
        @click="emit('dismissed')"
        v-text="$t('common.dismiss')"
      />
    </div>
  </div>
</template>

<script setup>
defineProps({
  notification: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['dismissed'])
</script>
