<template>
  <MediaLibraryRenderless
    v-slot="{
      getFileInputListeners,
      removeMedia,
      state,
      clearInvalidMedia,
      getImgProps,
    }"
    :initial-value="initialValue"
    :validation-rules="{
      accept: fileTypes,
      maxSizeInKB: maxSizeInKB,
    }"
    vapor
    :multiple="multiple"
    @has-uploads-in-progress-change="(e) => emit('upload-in-progress', !!e)"
    @changed="(media) => emit('changed', media)"
  >
    <slot
      :get-file-input-listeners="getFileInputListeners"
      :remove-media="removeMedia"
      :state="state"
      :clear-invalid-media="clearInvalidMedia"
      :get-img-props="getImgProps"
    />
    <div v-for="(invalidMedia, index) in state.invalidMedia" :key="index">
      <small
        v-for="(error, errorIndex) in invalidMedia.errors"
        :key="errorIndex"
        class="block text-[10px] text-red-500 subpixel-antialiased"
        v-text="getErrorMessage(error, invalidMedia.attributes.name)"
      />
    </div>
  </MediaLibraryRenderless>
</template>
<script setup>
import { MediaLibraryRenderless } from '@spatie/media-library-pro-vue3'
import { computed } from 'vue'

const { useI18n } = await import('vue-i18n')
const { t } = useI18n()

const props = defineProps({
  initialValue: {
    type: [Array, Object],
    required: true,
  },
  multiple: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  image: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  maxSizeInKB: {
    type: Number,
    required: false,
    default: () => 5120,
  },
  accept: {
    type: Array,
    required: false,
    default: () => [],
  },
})

const emit = defineEmits(['changed', 'upload-in-progress'])

const extensionsMapping = {
  'image/jpg': ['.jpg', '.jpeg'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/svg+xml': ['.svg'],
  'image/tiff': ['.tiff'],
  'audio/mpeg': ['.mp3'],
  'video/mp4': ['.mp4'],
  'text/csv': ['.csv'],
  'text/calendar': ['.ics'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/pdf': ['.pdf'],
  'application/zip': ['.zip'],
  'application/x-7z-compressed': ['.7z'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/x-tar': ['.tar'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
}

const fileTypes = computed(() => [
  ...(props.image ? ['image/jpg', 'image/jpeg', 'image/png'] : []),
  ...props.accept,
])

const extensions = computed(() => {
  const selectedExtensions = fileTypes.value.flatMap(
    (type) => extensionsMapping[type],
  )

  return [...new Set(selectedExtensions)]
})

const getErrorMessage = (error, filename) => {
  if (error.includes('You must upload a file of type')) {
    return t('media.validation.file.type.error', {
      filename,
      extensions: extensions.value.join(', '),
    })
  }
  if (error.includes('File too large')) {
    return t('media.validation.file.size.error', {
      filename,
      size: props.maxSizeInKB + 'KB',
    })
  }
}
</script>
