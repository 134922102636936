import { intlFormat, parse, format as dateFnsFormat } from 'date-fns'
import { useCurrentLocaleCode } from '@/composables/locale'

export const useBrowserTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const format = (date, format) =>
  intlFormat(new Date(date), format, { locale: useCurrentLocaleCode() })

export const formatDate = (date, timeZone) =>
  format(date, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timeZone ?? useBrowserTimezone(),
  })

export const formatTime = (date, timeZone) =>
  format(date, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: timeZone ?? useBrowserTimezone(),
  })

export const formatDatetime = (date, timeZone) =>
  format(date, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: timeZone ?? useBrowserTimezone(),
  })

export const parseDate = (date, formatString) => {
  return parse(date, formatString, new Date())
}

export const customFormat = (date, format) => dateFnsFormat(date, format)
