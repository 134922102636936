<template>
  <template
    v-for="newNotification in notifications"
    :key="newNotification.headline"
  >
    <NotificationBanner
      :message="newNotification.message"
      :headline="newNotification.headline"
    />
  </template>
</template>

<script setup>
import NotificationBanner from '@/Components/NotificationBanner.vue'
import { ref, watch } from 'vue'

const props = defineProps({
  notification: {
    type: [Object, null],
    default: null,
  },
})

const notifications = ref([])

watch(
  () => props.notification,
  (value) => {
    if (value) {
      notifications.value.push(value)
      setTimeout(() => {
        notifications.value.splice(notifications.value.length - 1, 1)
      }, 5000)
    }
  },
)
</script>
