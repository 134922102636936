import { useSimplifiedCurrentLocale } from '@/composables/locale'
// import { usePage } from '@inertiajs/vue3'
import { defineComponent } from 'vue'
import { useZendeskCredentials } from '@/composables/credentials'

export default defineComponent({
  setup() {
    const { jwt, widget_key } = useZendeskCredentials()
    // const user = usePage().props._user
    //
    // const planName = computed(() => {
    //   const company = usePage().props._user?.subscription_company
    //
    //   if (company.subscribed && !company.enterprise) {
    //     return 'Paid'
    //   }
    //   if (company.enterprise) {
    //     return 'Enterprise'
    //   }
    //
    //   return 'Free'
    // })

    const script = document.createElement('script')
    script.id = 'ze-snippet'
    script.defer = true
    script.async = true
    script.setAttribute(
      'src',
      `https://static.zdassets.com/ekr/snippet.js?key=${widget_key}`,
    )
    document.body.appendChild(script)

    script.onload = () => {
      window.zE('messenger:set', 'locale', useSimplifiedCurrentLocale())
      window.zE('messenger', 'loginUser', (callback) => callback(jwt))

      // fixme: these fields should not be customer editable
      // window.zE(
      //   'messenger:set',
      //   'conversationFields',
      //   [
      //     { id: '21705055212445', value: planName.value },
      //     { id: '21705034193693', value: user.subscription_company?.name },
      //   ],
      //   () => window.zE('messenger', 'show'),
      // )
    }

    return () => {}
  },
})
