import '../css/app.css'
import Layouts from '@/Layouts'
import Sentry from '@/Libraries/sentry'
import i18n from '@/Plugins/i18n'
import { createInertiaApp } from '@inertiajs/vue3'
import Vapor from 'laravel-vapor'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, h, defineAsyncComponent } from 'vue'
import { isDeployment } from '../../vite.helpers'

import('pusher-js')

const env = import.meta.env

Vapor.withBaseAssetUrl(env.VITE_VAPOR_ASSET_URL)

createInertiaApp({
  resolve: async (name) => {
    const page = await resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob('./Pages/**/*.vue'),
    )

    const layout = page?.default?.props?.layout?.default
    if (layout) {
      page.default.layout =
        Layouts[typeof layout === 'function' ? layout() : layout]
    } else {
      page.default.layout = Layouts.Default
    }

    return page
  },

  async setup({ el, App, props, plugin }) {
    const Vue = createApp({
      render: () => h(App, props),
    })

    if (isDeployment(env)) {
      await Sentry()
    }

    Vue.component(
      'FontAwesomeIcon',
      defineAsyncComponent(() => import('@/Libraries/fontawesome')),
    )
    Vue.use(plugin).use(i18n).mount(el)
  },
})
