import { useTranslations } from '@/composables/useWindow'
import { createI18n } from 'vue-i18n'

const locale = document.documentElement.lang

const messages = {}
messages[locale] = useTranslations()

const i18n = createI18n({
  legacy: false,
  locale,
  messages,
})

export default i18n
