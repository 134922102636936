<template>
  <main
    class="relative bg-white bg-cover subpixel-antialiased flex flex-col h-screen overflow-y-auto"
    :class="!isAuth && 'cloud-move bg-repeat-x'"
  >
    <AuthHeader v-if="isAuth || isLegalPages" />

    <div class="relative flex-1 flex flex-col z-[2]">
      <article class="relative flex-1">
        <slot />
      </article>
      <Footer v-if="isAuth || isLegalPages" />
    </div>

    <div
      v-if="isAuth || isLegalPages"
      class="bg-gradient-curve-container h-[500px] md:h-[590px] z-[1]"
    >
      <div
        class="cloud-move bg-repeat-x bg-center bg-white bg-fixed bg-cover h-full w-full absolute top-0 left-0"
        :style="{
          'background-image': `url('${Vapor.asset('images/bg-cloud-auth.png')}')`,
        }"
      ></div>
      <div class="bg-gradient"></div>
      <div class="bg-curved-slice"></div>
    </div>
  </main>
</template>

<script setup>
import Footer from '@/Components/Footer.vue'
import AuthHeader from '@/Components/AuthHeader.vue'
import Vapor from 'laravel-vapor'
import { usePage } from '@inertiajs/vue3'

const isAuth = usePage().component.includes('Auth/')
const isLegalPages = ['TermsOfUse', 'Imprint', 'PrivacyPolicy'].includes(
  usePage().component,
)
</script>
