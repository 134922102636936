<template>
  <div
    class="user-dropdown-wrapper animate-dropdown relative z-[10] flex cursor-pointer items-start gap-[6px] tracking-[0.2px] text-[rgba(255,255,255,0.5)] subpixel-antialiased hover:text-white"
    :class="[browserWidth < 1024 ? isShownOnMobile && 'show' : '']"
  >
    <div
      class="group relative top-[-2px] flex cursor-pointer items-center"
      @click="isShownOnMobile = !isShownOnMobile"
    >
      <div class="mr-3">
        <Avatar
          class="h-[33px] w-[33px]"
          :size="'33px'"
          :allow-update="false"
          :allow-delete="false"
          :initial-value="user.avatar"
        />
      </div>

      <span
        v-if="!pageHaveEvent"
        class="mr-[8px] hidden whitespace-nowrap text-sm font-semibold text-[rgba(255,255,255,0.5)] group-hover:text-white 2xl:block"
        v-text="`${user.first_name} ${user.last_name}`"
      />

      <FontAwesomeIcon
        :icon="['fas', 'angle-down']"
        class="hidden text-sm text-[rgba(255,255,255,0.5)] group-hover:text-white 2xl:block"
      />
    </div>
    <div
      class="dropdown-div absolute top-[30px] right-0 w-[200px] overflow-y-auto rounded-[0_0_5px_5px] text-[#7b8190] subpixel-antialiased"
    >
      <div class="h-full pt-[26px] pb-1 pr-1">
        <div
          class="bg-white p-[18px_15px_10px_15px] shadow-[0_5px_6px_rgb(52,58,64,0.10)] overflow-y-auto max-h-[500px]"
        >
          <h4
            class="mb-[8px] text-[11px] font-bold uppercase tracking-[1px] text-[#3f1e59] opacity-[0.4]"
            v-text="`${$t('common.welcome')} ${user.first_name}`"
          />

          <div class="block lg:hidden">
            <button
              class="py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              @click="
                () => {
                  clickNotif()
                  isShownOnMobile = false
                }
              "
            >
              <p class="text-xs font-bold">{{ $t('common.notifications') }}</p>
            </button>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>

          <div class="hidden lg:block">
            <Link
              role="button"
              class="block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              :href="useRoute('account.settings.show')"
              @click="isShownOnMobile = false"
            >
              <p class="text-xs font-bold" v-text="$t('common.settings')" />
            </Link>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>

          <div class="block lg:hidden">
            <Link
              role="button"
              class="block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              :href="useRoute('account.settings.show')"
              @click="isShownOnMobile = false"
            >
              <p class="text-xs font-bold" v-text="$t('common.my_profile')" />
            </Link>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>
          <div v-if="usePermission('company:view')" class="block lg:hidden">
            <Link
              class="block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              :href="useRoute('companies.show')"
              @click="isShownOnMobile = false"
            >
              <p
                class="text-xs font-bold"
                v-text="$t('settings.tab.my_company')"
              />
            </Link>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>
          <div v-if="usePermission('user:list')" class="block lg:hidden">
            <Link
              class="block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              :href="useRoute('companies.users.list')"
              @click="isShownOnMobile = false"
            >
              <p class="text-xs font-bold" v-text="$t('settings.my_users')" />
            </Link>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>
          <div v-if="usePermission('team:list')" class="hidden">
            <Link
              class="block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              :href="useRoute('users.teams.index')"
              @click="isShownOnMobile = false"
            >
              <p class="text-xs font-bold" v-text="$t('team.my_teams')" />
            </Link>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>
          <div v-if="usePermission('company:view')" class="block lg:hidden">
            <Link
              class="block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              :href="useRoute('companies.subscriptions.show')"
              @click="isShownOnMobile = false"
            >
              <p
                class="text-xs font-bold"
                v-text="$t('common.my_subscription')"
              />
            </Link>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>
          <div
            v-if="usePermission('company:view') && hasAPIAccess()"
            class="block lg:hidden"
          >
            <Link
              class="block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              :href="useRoute('apiKeys.list')"
              @click="isShownOnMobile = false"
            >
              <p class="text-xs font-bold" v-text="$t('apiKey')" />
            </Link>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>
          <div v-if="usePage().props._companies.length > 1">
            <div
              class="block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              @click="
                () => {
                  showCompanySwitcher = true
                  isShownOnMobile = false
                }
              "
            >
              <p
                class="text-xs font-bold"
                v-text="$t('common.switch_company')"
              />
            </div>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>
          <div class="block lg:hidden">
            <a
              :href="useHelpCenterUrl()"
              target="_blank"
              rel="noopener"
              class="block py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
              @click="isShownOnMobile = false"
            >
              <p class="text-xs font-bold" v-text="$t('common.help_center')" />
            </a>
            <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
          </div>
          <div
            class="block cursor-pointer py-[10px] px-[6px] mx-[-6px] hover:bg-[#F8F4FF] hover:text-[#9C63FF] transition-all ease-in-out duration-200 rounded"
            @click="
              () => {
                logout()
                isShownOnMobile = false
              }
            "
          >
            <p class="text-xs font-bold" v-text="$t('common.logout')" />
          </div>
          <div class="border-b border-dashed border-[#dbdbdb] my-2"></div>
        </div>
      </div>
    </div>
  </div>

  <SuspenseWrapper v-if="showCompanySwitcher">
    <SwitchCompanyModal @close="showCompanySwitcher = false" />
  </SuspenseWrapper>
</template>

<script setup>
import Avatar from '@/Components/Avatar.vue'
import SwitchCompanyModal from '@/Components/Users/SwitchCompanyModal.vue'
import SuspenseWrapper from '@/Components/common/SuspenseWrapper.vue'
import { usePermission } from '@/composables/permission'
import { useRoute } from '@/composables/route'
import { useHelpCenterUrl } from '@/composables/menu'
import { router, Link, usePage } from '@inertiajs/vue3'
import { computed, ref } from 'vue'
import { hasAPIAccess } from '@/composables/subscription'

const pageHaveEvent = computed(() => usePage().props.event)
const user = computed(() => usePage().props._user)
const showCompanySwitcher = ref(false)
const isShownOnMobile = ref(false)
const browserWidth = window.innerWidth

const logout = () =>
  router.post(useRoute('logout'), null, {
    onSuccess: () => {
      window.zE('messenger', 'logoutUser')
      window.zE('messenger', 'hide')
    },
  })

const clickNotif = () => {
  document.querySelector('body').click()
  document.getElementById('notif-menu-btn').click()
}

onclick = (e) => {
  let el = document.querySelector('.user-dropdown-wrapper')
  if (el) {
    const isClickInside = el.contains(e.target)
    if (!isClickInside) {
      isShownOnMobile.value = false
    }
  }
}
</script>
