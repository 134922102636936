export default async () => {
  const { init, replayIntegration, browserTracingIntegration } = await import(
    '@sentry/vue'
  )

  init({
    dsn: 'https://032a1b2e4b3641bfb5e73d5981d1b019@us.sentry.io/4504605608640512',
    tracePropagationTargets: [
      // /^https:\/\/staging\.airlst\.app/,
      /^https:\/\/airlst\.app/,
    ],
    integrations: [browserTracingIntegration(), replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.01,
    // Session Replay
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.05,
  })
}
